import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { io } from "socket.io-client";
import VueSocketIO from "vue-3-socket.io";
import VuePageTransition from "vue-page-transition";

const socketConnection = io(process.env.VUE_APP_SOCKET_URL, {
	withCredentials: true,
	auth: { token: localStorage.getItem("authKey") },
});

createApp(App)
	.use(router)
	.use(store)
	.use(VuePageTransition)
	.use(new VueSocketIO({ connection: socketConnection }))
	.mount("#app");
