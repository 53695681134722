
	import { Options, Vue } from "vue-class-component";
	import { Getter, Mutation } from "s-vuex-class";
	import { CurrentBotsEntry } from "./expgenshared/CurrentBotsResponse";
	import { MessageType } from "./expgenshared/MessageTypes";
	import { ErrorMessage } from "./expgenshared/ErrorMessage";
	import { FadeInOut } from "vue3-transitions/esm";
	import Navbar from "./components/Navbar.vue";
	import Swal from "sweetalert2";

	@Options({
		components: {
			Navbar,
			FadeInOut,
		},
		sockets: {
			getActiveBotsResponse(message: CurrentBotsEntry[]) {
				this.setBots(message);
			},
			error(error: ErrorMessage) {
				Swal.fire({
					toast: true,
					timer: 2000,
					icon: "error",
					title: error.title,
					text: error.message,
					position: "top-right",
				});
			},
		},
	})
	export default class Home extends Vue {
		$socket!: any;

		@Mutation("setBots") setBots: any;

		@Getter bots: CurrentBotsEntry[];

		mounted() {
			this.$socket!.emit(MessageType.ACTIVE_BOTS);
		}
	}
