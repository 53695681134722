import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Overview from "../views/Overview.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Overview",
		component: Overview,
	},
	{
		path: "/bot/:id",
		name: "Bot",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ "../views/Bot.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/Login.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from) => {
	if (to.name != "login" && localStorage.getItem("authKey") == undefined) {
		return "/login";
	}
});

export default router;
