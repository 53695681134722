import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import { CurrentBotsEntry } from "../expgenshared/CurrentBotsResponse";

@Module
export default class State extends VuexModule {
	currentbots: CurrentBotsEntry[] = [];

	@Mutation
	setBots(bots: CurrentBotsEntry[]) {
		this.currentbots = bots;
	}
	get bots(): CurrentBotsEntry[] {
		return this.currentbots;
	}

	get bot() {
		return (id: string) => {
			return this.bots.find((bot) => bot.uuid == id);
		};
	}
}
