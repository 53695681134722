
	import { Getter } from "s-vuex-class";
	import { Options, Vue } from "vue-class-component";
	import { Watch } from "vue-property-decorator";
	import { CurrentBotsEntry } from "../expgenshared/CurrentBotsResponse";
	import { MessageType } from "../expgenshared/MessageTypes";

	@Options({
		sockets: {
			getBotXpResponse(resp) {
				this.xpToday += resp[0];
			},
		},
	})
	export default class Overview extends Vue {
		@Getter bots: CurrentBotsEntry[];
		xpToday: number = 0;
		sockets!: any;
		$socket!: any;

		@Watch("bots")
		botsLoaded() {
			this.getTotalxp();
		}

		getTotalxp() {
			for (let bot of this.bots) {
				this.$socket.emit(MessageType.GET_XP, bot.uuid);
			}
		}

		get formattedxp() {
			return this.xpToday.toLocaleString("en-GB");
		}

		mounted() {
			this.getTotalxp();
		}

		unmounted() {
			this.sockets.unsubscribe(MessageType.GET_XP_RESPONSE);
		}
	}
