export enum MessageType {
	CHAT_MESSAGE = "newChatMessage",
	ACTIVE_BOTS = "getActiveBots",
	ACTIVE_BOTS_REPONSE = "getActiveBotsResponse",
	BOT_STATUS = "getBotStatus",
	BOT_STATUS_RESPONSE = "getBotStatusResponse",
	KILL_BOT = "killBot",
	KILL_BOT_RESPONSE = "killBotResponse",
	START_BOT = "startBot",
	START_BOT_RESPONSE = "startBotResponse",
	SEND_CHAT_MESSAGE = "sendChatMessage",
	GET_XP = "getBotXp",
	GET_XP_RESPONSE = "getBotXpResponse",
	WORLD_UPDATE = "worldUpdate",
	STATE_CHANGE = "stateChange",
	ERROR = "error",
}
